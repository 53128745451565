import React from "react"
import Link from "gatsby-link"
// import CoffeeImg from '../../../static/img/coffee.png'

const PostCard = ({ posts }) => {
  return (
    <div className="section is-paddingless">
      {posts.map(({ node: post }) => (
        <div
          className="news_item is-size-5"
          id="media_post-component"
          key={post.id}
        >
          <h5>{post.frontmatter.title}</h5>
          <img src={post.frontmatter.cover} />
          <p>{post.frontmatter.meta_description}</p>
        </div>
      ))}
    </div>
  )
}

export default PostCard
